import Image from 'next/image';
import Link from 'next/link';

import { GradientBg } from '@/shared/components/animation/gradient-background';
import { Article } from '@/shared/components/article';
import { ContactModal } from '@/shared/components/contact-modal';
import { TextGradient } from '@/shared/components/text-gradient';
import { Button } from '@/shared/components/ui/button';

export const AboutSchool = () => {
  return (
    <Article tag="main">
      <div className="grid grid-cols-1 items-center gap-x-4 sm:grid-cols-[1fr_minmax(200px,_40%)] sm:gap-x-10">
        <div className="mx-auto sm:mx-0">
          <div className="mb-10 sm:mb-5 sm:max-w-[620px]">
            <TextGradient
              text="Деловой английский онлайн"
              tag="h1"
              className="mb-3 !text-wrap text-center text-4xl sm:mb-4 sm:max-w-lg sm:text-left lg:text-5xl"
            />

            <p className="mx-auto text-center text-sm xss:max-w-md sm:mx-0 sm:max-w-xl sm:text-left sm:text-base">
              Только необходимая грамматика и&nbsp;лексика для вашей профессии. Начните заниматься сегодня, чтобы
              получить иностранный оффер уже через полгода!
            </p>
          </div>

          <div className="flex flex-col items-center gap-6 sm:items-start lg:flex-row lg:items-center">
            <ContactModal entryPoint="MAIN_PAGE">
              <Button variant="cta" className="px-6" aria-label="Записаться на бесплатную консультацию">
                Бесплатная консультация
              </Button>
            </ContactModal>

            <Link
              className="sm:text-md text-sm decoration-brand-accent-dark hover:underline dark:decoration-brand-accent-normal"
              href="/cases"
              aria-label="Перейти к кейсам наших учеников"
            >
              Читать кейсы учеников
            </Link>
          </div>
        </div>

        <div className="relative hidden items-center justify-end overflow-visible sm:flex 2xl:justify-center">
          <div className="min-h-[500px] max-w-full">
            <Image
              className="mx-auto hidden h-full w-full object-contain sm:inline-flex"
              fill
              src="/images/main.webp"
              alt="Anglichannka для компаний"
              loading="lazy"
            />
          </div>

          <GradientBg
            className="sm:var(--radial-size,100px) hidden sm:block"
            type="circle"
            origin="center"
            size={300}
          />
        </div>
      </div>
    </Article>
  );
};
